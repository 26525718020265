import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'fr',
    resources: {
      fr: {
        translation: {
          fresques: "Fresques",
          about: "À propos",
          contact: "Contact",
          artwork_titles: {
            "1": "Janvier 2023",
            "2": "Février 2024",
            "3": "Décembre 2023",
            "4": "Janvier 2024",
            "5": "Décembre 2023",
            "6": "Mars 2024",
            "7": "Novembre 2023",
          },
          artwork_descriptions: {
            "1": "La Fleur Bleue de Joie peinte en outremer doux et cyan tyran dépeint l’Amour inconditionnel.",
            "2": "", 
            "3": "Portrait de quelqu'un que j'aime.", 
            "4": "Discussion avec quelqu'un que j'aime.", 
            "5": "Autoportrait.", 
            "6": "",
            "7": "",  
          },
          artwork_sizes: {
            "1": "Encre de chine et aquarelle sur papier - 150x150cm",
            "2": "Aquarelle sur papier - 100x100cm",
            "3": "Aquarelle sur papier - 65x65cm",
            "4": "Aquarelle sur papier - 100x100cm",
            "5": "Aquarelle sur papier - 65x65cm",
            "6": "Aquarelle sur papier - 100x100cm",
            "7": "Aquarelle sur papier - 65x65cm",
          },
          about_page: {
            extitle : 'Expositions',
            awtitle:'Prix',
            biography: `Kajika Aki Ferrazzini est née en 1993 à Paris. Après avoir obtenu un diplôme des Métiers d'Arts et du Design à l’école Estienne en 2016, elle commence un Master CRFA (Concepteur et Réalisateur de films d’animation) à l’école des Gobelins qu’elle arrête en 2017 pour se consacrer à son premier court-métrage MOM. Produit en solo dans une période d’un an, MOM a gagné le prix du Meilleur film au Festival International d’Animation de Tbilisi (Georgie, 2021), a obtenu une Mention spéciale au Future Film Festival (Italie, 2021), au Anima - Best of shorts 2022 (Belgique), et a fait partie de la sélection officielle du Festival du film International d’Annecy en 2021.

En 2019 et 2020, elle réalise The life and the death of the little mermaid et IMIEAMORI, deux oeuvres picturales de 1m50 sur 10m de long, réalisées entièrement sur papier, sans esquisse préalable, à l’encre de chine et aquarelle.

Ses oeuvres sont exposées du 16 mars 2022 au 16 mai 2022 à la Folie Barbizon, sous l’œil bienveillant de Lionel Bensemoun : « Kajika embrasse la nature humaine en dévoilant sa propre intimité, ses personnages oscillent entre les émotions violentes et douces. Ils pleurent, vomissent, tuent ; ils rient, embrassent, aiment, et fleurissent aussi, dans une constante recherche d'harmonie au sein du chaos, car pour elle c’est au sein des plus grands drames que l'être humain peut trouver l'opportunité de laisser s'épanouir en lui les plus douces fleurs d'éternité. »`,
            exhibitions: [
              {
                year: "2024",
                title: "Claim for Peace",
                organized_by: "Tresor Foundation",
                city: "Berlin",
                venue: "",
                dates: "déc 2024",
                type: "SOLO",
              },
              {
                year: "2022",
                title: "Atomic Bombs",
                organized_by: "",
                city: "",
                venue: "La Folie Barbizon",
                dates: "avril 2022",
                type: "SOLO",
              },
            ],
            awards: [
              {
                title: "Prix du Meilleur film",
                event: "Festival International d’Animation de Tbilisi, Géorgie",
                year: 2021,
              },
              {
                title: "Mention spéciale",
                event: "Future Film Festival, Italie",
                year: 2021,
              },
              {
                title: "Mention spéciale",
                event: "Anima - Best of shorts, Belgique",
                year: 2022,
              },
            ],
          },
          frescoes_page: {
            title: "KAJIKA - Fresques",
            left_image_alt: "The life and death of the little mermaide",
            right_image_alt: "Imieiamori",
            left_title: "The life and death of the little mermaid",
            right_title: "Imieiamori",
            left_subtitle: "1,5x10m, encre sur papier",
            right_subtitle: "1,5x10m, encre et aquarelle sur papier"
          },
          flowers_page: {
            introduction: "Les Fleurs de Joie sont composées de 5 branches et de 5 pétales, d’un cœur en forme d'étoile et de 5 petits points.",
            process_title: "Le process de création des Fleurs de Joie :",
            process: [
              "Tout d'abord, je découpe un carré de papier, où je définis le cadre à l'aide d'un crayon. Le cadre est important parce que les limites définissent la finalité.",
              "Avoir un commencement et une fin définit une œuvre d'art. Le temps investi dans le carré définit l'œuvre. Le carré central est l'endroit où la structure et les couleurs se posent.",
              "Mes couleurs sont créées avec des pigments et des liants trouvés et testés pour leurs qualités uniques. Je confectionne avec soins mes aquarelles, ma peinture : La justesse de la couleur définit la justesse du propos.",
              "Les couleurs sont organisées et ajustées au sein d'une forme : les Fleurs de Joie.",
              "Cette forme est l'expression trouvée par un corps animé par l'énergie de la Joie.",
              "Toutes les couleurs sont appliquées sans réflexion, ni hésitation : la peinture est déjà présente au moment où je découpe le papier.",
              "Ses peintures n'ont ni idée, ni symbolisme; ce sont des oeuvres de causalité : C'est l'œuvre qui instaure l'énergie de création. C'est le carré blanc qui appelle et définit la couleur.",
              "Mes carrés de Fleurs de Joie, ou Fleurs Bleues ne sont autre que des Carrés de Temps.",
              "Un espace libre et fermé où se pose le Flux.",
            ],
            closing: "",
            image_alt: "Les Fleurs",
          },
          meta: {
            description: "Artiste, peintre, illustratrice, auteure et réalisatrice de film d’animation, Kajika Aki dépeint sa réalité au travers de son oeuvre artistique. Son travail navigue entre violence et douceur, réalisme et abstraction; son oeuvre est un cadre où la peine humaine et les plus grandes épreuves peuvent donner naissance à la justesse de la ligne et à la précision."
          }
        }
      },
      en: {
        translation: {
          fresques: "Frescoes",
          about: "About",
          contact: "Contact",
          artwork_titles: {
            "1": "January 2023",
            "2": "February 2024",
            "3": "December 2023",
            "4": "January 2024",
            "5": "December 2023",
            "6": "March 2024",
            "7": "November 2023",
          },
          artwork_descriptions: {
            "1": "The Blue Flower of Joy painted in soft ultramarine and tyrant cyan depicts unconditional Love.",
            "2": "", 
            "3": "Portrait of someone I love.", 
            "4": "Discussion with someone I love.", 
            "5": "Self-portrait.", 
            "6": "",
            "7": "",  
          },
          artwork_sizes: {
            "1": "Indian ink and watercolor on paper - 150x150cm",
            "2": "Watercolor on paper - 100x100cm",
            "3": "Watercolor on paper - 65x65cm",
            "4": "Watercolor on paper - 100x100cm",
            "5": "Watercolor on paper - 65x65cm",
            "6": "Watercolor on paper - 100x100cm",
            "7": "Watercolor on paper - 65x65cm",
          },
          about_page: {
            extitle : 'Exhibitions',
            awtitle:'Awards',
            biography: `Kajika Aki Ferrazzini was born in 1993 in Paris. After obtaining a degree in Arts and Design at the Estienne school in 2016, she began a Master's degree in CRFA (Animation Film Designer and Director) at Gobelins, which she left in 2017 to focus on her first short film MOM. Produced solo over a period of one year, MOM won the Best Film award at the Tbilisi International Animation Festival (Georgia, 2021), received a Special Mention at the Future Film Festival (Italy, 2021) and Anima - Best of shorts 2022 (Belgium), and was part of the official selection of the Annecy International Film Festival in 2021.

In 2019 and 2020, she created The Life and the Death of the Little Mermaid and IMIEAMORI, two pictorial works measuring 1.5m by 10m in length, made entirely on paper, without preliminary sketches, using Indian ink and watercolor.

Her works were exhibited from March 16, 2022, to May 16, 2022, at La Folie Barbizon, under the watchful eye of Lionel Bensemoun: "Kajika embraces human nature by revealing her own intimacy, her characters oscillate between violent and gentle emotions. They cry, vomit, kill; they laugh, embrace, love, and bloom, in a constant search for harmony within chaos, for her it is within the greatest dramas that the human being can find the opportunity to let the sweetest flowers of eternity blossom in themselves."`,
            exhibitions: [
              {
                year: "2024",
                title: "Claim for Peace",
                organized_by: "Tresor Foundation",
                city: "Berlin",
                venue: "",
                dates: "Dec 2024",
                type: "SOLO",
              },
              {
                year: "2022",
                title: "Atomic Bombs",
                organized_by: "",
                city: "",
                venue: "La Folie Barbizon",
                dates: "April 2022",
                type: "SOLO",
              },
            ],
            awards: [
              {
                title: "Best Film Award",
                event: "Tbilisi International Animation Festival, Georgia",
                year: 2021,
              },
              {
                title: "Special Mention",
                event: "Future Film Festival, Italy",
                year: 2021,
              },
              {
                title: "Special Mention",
                event: "Anima - Best of Shorts, Belgium",
                year: 2022,
              },
            ],
          },
          frescoes_page: {
            title: "KAJIKA - Frescoes",
            left_image_alt: "The life and death of the little mermaid",
            right_image_alt: "Imieiamori",
            left_title: "The life and death of the little mermaid",
            right_title: "Imieiamori",
            left_subtitle: "1.5x10m, ink on paper",
            right_subtitle: "1.5x10m, ink and watercolor on paper"
          },
          flowers_page: {
            introduction: "The Flower of Joy is made up of 5 branches and 5 petals, a star-shaped heart and 5 small dots.",
            process_title: "The process of creating Flowers of Joy :",
            process: [
              "First, I cut out a square of paper and pencil in the frame. The frame is essential because boundaries define space.",
              "To have a beginning and an end defines a Work of Art. The time spent in the square defines the Work. The central square is where structure and colours come together.",
              "My colours are created with pigments and binders that have been found and tested for their unique qualities. My watercolours and paints are carefully prepared: the right colour defines the right message.",
              "The colours are organised and balanced within a form: Flowers of Joy.",
              "This shape is the expression of a body driven by the energy of Joy.",
              "All the colours are applied without thought or hesitation: the colour is already there when the paper is cut.",
              "My paintings have no ideas or symbolism; they are works of causality: it is the work that sets the energy of creation in motion. It is the Whiteness of the square that invokes and defines the colour.",
              "My Flowers of Joy or Blue Flowers squares are nothing more than Squares of Time.",
              "A free and closed space where Flow takes place.",
            ],
            closing: "Hoping you'll love it as much as I do,",
            image_alt: "The Flowers",
          },          
          meta: {
            description: "Kajika Aki is an artist, painter, illustrator, author and animator who confidently depicts her reality through her art. Her work boldly navigates between violence and gentleness, realism and abstraction. Her art is a testament to the resilience of the human spirit, where pain and hardship give birth to precision and truth."
          }
        }
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
