import React, { useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Lenis from '@studio-freight/lenis';
import Header from '../components/Header';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

gsap.registerPlugin(ScrollTrigger);

const Flowers = () => {
  const { t } = useTranslation();
  const introRef = useRef(null);
  const textRef = useRef(null);
  const arrowRef = useRef(null);
  const lenisRef = useRef(null);

  useEffect(() => {
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
      smoothWheel: true,
    });
    lenisRef.current = lenis;

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }
    requestAnimationFrame(raf);

    gsap.fromTo(
      arrowRef.current,
      { y: -10 },
      { y: 10, repeat: -1, yoyo: true, ease: 'power1.inOut', duration: 2 }
    );

    ScrollTrigger.create({
      trigger: textRef.current,
      start: 'top bottom',
      onEnter: () => {
        gsap.to(arrowRef.current, { opacity: 0, duration: 0.5 });
      },
      onLeaveBack: () => {
        gsap.to(arrowRef.current, { opacity: 1, duration: 0.5 });
      },
    });
  }, []);

  return (
    <Box>
      <Header />
      <Box
        ref={introRef}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          backgroundColor: 'white',
          position: 'relative',
        }}
      >
        <Typography
          variant="h4"
          sx={{
            px: { xs: 3, md: 10 },
            maxWidth: '800px',
            fontFamily: 'Manrope, sans-serif',
            mb: 0.5,
            lineHeight: '1.4em',
          }}
        >
          {t('flowers_page.introduction')}
        </Typography>
        <Box
          ref={arrowRef}
          sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '1.5rem',
            cursor: 'pointer',
          }}
        >
          <KeyboardArrowDownIcon fontSize="medium" />
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <Box
          ref={textRef}
          sx={{
            backgroundColor: 'white',
            lineHeight: '2',
            px: { xs: 3, md: 10 },
            maxWidth: '800px',
          }}
        >
          <Typography
            variant="h5"
            sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}
          >
            {t('flowers_page.process_title')}
          </Typography>
          {t('flowers_page.process', { returnObjects: true }).map((paragraph, index) => (
            <Typography
              key={index}
              variant="h5"
              sx={{ fontFamily: 'Manrope, sans-serif', lineHeight: '1.8em', mb: 4 }}
            >
              {paragraph}
            </Typography>
          ))}
          <Typography
            variant="h5"
            sx={{
              width: '100%',
              fontFamily: 'Manrope, sans-serif',
              lineHeight: '1.8em',
              mb: 2,
              textAlign: 'left',
            }}
          >
            {t('flowers_page.closing')}
          </Typography>
          <Typography
            variant="h5"
            sx={{
              width: '100%',
              fontFamily: 'Manrope, sans-serif',
              lineHeight: '1.8em',
              mb: 2,
              textAlign: 'right',
            }}
          >
            Kajika.
          </Typography>
        </Box>
      </Box>
      <Box
        component="img"
        src="/assets/images/flower.jpg"
        alt={t('flowers_page.image_alt')}
        sx={{
          width: { xs: '100%', md: '80%' },
          height: 'auto',
          margin: '0 auto 50px',
          display: 'block',
        }}
      />
    </Box>
  );
};

export default Flowers;
